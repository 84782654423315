import utils from '../services/utils';

//import constants from '../constants';
//const apiUrl = `//bs-api.bram.odev.local.schuttelaar.net`;
const apiUrl = `https://bandenspanningapi.demo.o.schuttelaar.net`;
//const apiUrl = `http://bandenspanning.mark.odev.local.schuttelaar.net`;
//const apiUrl = `https://api.watismijnbandenspanning.nl`;

const fetchOptions = {
	method: 'GET', // *GET, POST, PUT, DELETE, etc.
	mode: 'cors', // no-cors, *cors, same-origin
	cache: 'default' // *default, no-cache, reload, force-cache, only-if-cached
}

class Bapi {
	data; tyres; makes; brandId; serieId; modelId; typeId;

	getData(licensePlate) {
		//licensePlate = "PK464J";
		return fetch(`${apiUrl}/api.php?call=car&licenceplate=${licensePlate}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) {
						console.log('error', error);
						return {msg: 'error'};
					}
					if (typeof result === 'string' && result === "RDW API - CarMake / CarModel is not found!") {
						return {error: 1, msg: 'error', data: null}
					}
					//result = null;
					if (!result || !result.CarMake || result.error || !result.PressureData) {
						let data = {};
						if (result && result.data) { // there may be an incomplete result
							data = result.data;
						}
						return { msg: 'carmakenotfound', data: data };
					}

					console.log('data', result);

					this.data = result;
					console.log('result', result);
					return { msg: 'success', data: result };
				}
			)
	}

	getCarDataManual(selection) {
		return fetch(`${apiUrl}/api.php?call=tyres&brand=${selection.make}&type=${selection.type}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) {
						console.log('error', error);
						return { msg: 'error' };
					}
					this.data = {Tyres: result};
					return { msg: 'success', data: {Tyres: result} };
				}
			)
	}

	getTyrePressures(tyreId, coefficient) {
		//tyreId = 664781; //known
		//tyreId = 664783; //unknown
		return fetch(`${apiUrl}/api.php?call=tyrepressure&tyre=${tyreId}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) {
						console.log('error', error);
						return { msg: 'error' };
					} else if (typeof result !== 'object' || result.error) {
						return { msg: 'noresult' }
					} else {
						result = {
							PressureFront: (parseFloat(result.PressureFront) + parseFloat(coefficient)).toFixed(1),
							PressureRear: (parseFloat(result.PressureRear) + parseFloat(coefficient)).toFixed(1)
						}
					}

					return { msg: 'success', data: result };
				}
			)
	}

	getBrands() {
		return fetch(`${apiUrl}/api.php?call=brands`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) return console.log('error', error);
					let makes = [];
					for (let i in result) {
						const make = result[i];
						makes.push({value: make.BrandId, label: make.BrandName})
					}
					this.makes = makes;
					return makes;
				}
			)
	}

	getSeries(brandId) {
		this.brandId = brandId;
		return fetch(`${apiUrl}/api.php?call=series&brand=${brandId}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) return console.log('error', error);
					let series = [];
					for (let i in result) {
						const serie = result[i];
						series.push({ value: serie.SerieId, label: serie.SerieName })
					}
					this.series = series;
					return series;
				}
			)
	}

	getYears(modelId) {
		return Promise.resolve('ok').then(() => {
			return utils.getYearsFromNow(30).map((option, index) => {
				return { value: option, label: option };
			});
		});
	}

	getModels(serieId, selected) {
		this.serieId = selected.serie;
		return fetch(`${apiUrl}/api.php?call=models&brand=${this.brandId}&serie=${selected.serie}&year=${selected.year}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) return console.log('error', error);
					let models = [];
					for (let i in result) {
						const model = result[i];
						models.push({ value: model.ModelId, label: model.ModelName })
					}
					this.models = models;
					return models;
				}
			)
	}

	getTypes(year, selected) {
		this.modelId = selected.model;
		return fetch(`${apiUrl}/api.php?call=types&brand=${this.brandId}&model=${selected.model}&year=${selected.year}`, fetchOptions)
			.then(res => res.json())
			.then(
				(result, error) => {
					if (error) return console.log('error', error);
					let types = [];
					for (let i in result) {
						const type = result[i];
						if (!type.PressureData) {
							continue;
						}
						types.push({ value: type.TypeId, label: type.TypeName })
					}
					this.types = types;
					return types;
				}
			)
	}

	getLabelValuePair(type, option) {
		let label;
		if (option.Front && option.Rear && option.Front !== option.Rear) {
			label = `${option.Front} / ${option.Rear}`;
		} else {
			label = option.Front;
		}
		switch(type) {
			case 'tyre-oem':
			default:
				return {
					value: option.FittingId,
					label: label
				}
			case 'tyre-custom':
				return {
					value: option.FittingId,
					label: label
				}
		}
	}

	getOems() {
		if (!this.data) return null;
		let oems = [];
		for (let i in this.data.Tyres) {
			let tyre = this.data.Tyres[i];
			if (tyre.OEM) {
				oems.push(this.getLabelValuePair('tyre-oem', tyre));
			}
		}
		return oems;
	}

	getCustom() {
		if (!this.data) return null;
		let custom = [];
		for (let i in this.data.Tyres) {
			let tyre = this.data.Tyres[i];
			if (!tyre.OEM) {
				custom.push(this.getLabelValuePair('tyre-custom', tyre));
			}
		}
		return custom;
	}

};

export default new Bapi();
